/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react';
import { MdEdit } from 'react-icons/md';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import IntlTelInput from 'react-intl-tel-input';
import moment from 'moment';

function Data({
  click,
  show,
  visible,
  checked,
  setEmail,
  setPasswordLogin,
  setSurnameSignUp,
  setNameSignUp,
  setEmailSignUp,
  setPhoneSignUp,
  setTitle,
  setGender,
  setZipCode,
  setCity,
  setStreet,
  setNumber,
  setBirthday,
  surnameSignUp,
  nameSignUp,
  emailSignUp,
  phoneSignUp,
  title,
  gender,
  zipCode,
  city,
  street,
  number,
  birthday,
  passwordForgoten,
  isITW,
  t,
}) {
  const [phoneValue, setPhoneValue] = useState('');

  const onHandlePhoneSignUp = (isValid, phoneInputValue, country, fullPhoneInput) => {
    // let number;
    // if (window.intlTelInputUtils && window.intlTelInputUtils.formatNumber) {
    //   const format = window.intlTelInputUtils.numberFormat.INTERNATIONAL;
    //   number = window.intlTelInputUtils.formatNumber(phoneInputValue, country.iso2, format);
    // } else {
    //   number = phoneInputValue ? phoneInputValue : '';
    // }
    setPhoneValue(phoneInputValue);
    setPhoneSignUp(fullPhoneInput.replace(/[ +]/g, ''));
    // setCountryCode(country.iso2.toUpperCase());
    // setIsValidPhone(isValid);
    // setPhoneSignUp(event.target.value);
  };

  return (
    <>
      {visible === true ? (
        <ith-div class="border mt-3">
          <ith-div
            class="flex  justify-between md:p-5 p-3 md:py-6 py-4 cursor-pointer select-none"
            onClick={() => { !checked ? click(1) : ''; }}
          >
            <ith-div class="font-semibold text-gray-300 tracking-tight">
              <ith-div>
                <ith-p class="flex">
                  <ith-div
                    class="gridColor text-white flex items-center justify-center rounded-full mr-5 text-md h-6 w-6"
                  >
                    2
                  </ith-div>
                  {t('bookingStep2')}
                </ith-p>
                {show === false ? (
                  <ith-div class="flex flex-col">
                    <ith-p class="ml-12 mt-5">
                      {surnameSignUp}
                      {' '}
                      {nameSignUp}
                    </ith-p>
                    <ith-p class="ml-12">{emailSignUp}</ith-p>
                    <ith-p class="ml-12">{phoneSignUp}</ith-p>
                  </ith-div>
                ) : (
                  ''
                )}
              </ith-div>
            </ith-div>
            {show === false && checked === false ? <MdEdit className="text-xl text-gray-300" /> : ''}
            {checked === true ? <CheckCircleIcon className="text-xl text-green-700" /> : ''}
          </ith-div>
          {show === true ? (
            <>
              <ith-div class="grid grid-cols-1 gap-4 items-center justify-between md:p-5 md:py-1 p-3 ">
                <ith-p class="text-lg ">{t('label_login')}</ith-p>
              </ith-div>
              <form action="#" onSubmit={() => { click(2); return false; }}>
                <ith-div class="grid lg:grid-cols-3  md:grid-cols-3 gris-cols-1 flex items-end pl-8 border-b md:mr-1 mr-8">
                  <ith-div class=" pl-4 mb-4 mt-2">
                    <label
                      className="block text-gray-700 text-sm mb-1"
                      htmlFor="emailSignIn"
                    >
                      {t('label_email')}
                      {' '}
                      <span className="text-red-600">*</span>
                    </label>
                    <input
                      className="appearance-none border rounded w-full py-1.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="emailSignIn"
                      type="email"
                      placeholder={t('P.email')}
                      onChange={(event) => setEmail(event.target.value)}
                      required
                    />
                  </ith-div>
                  <ith-div class=" ml-4 mb-4 mt-2">
                    <label
                      className="block text-gray-700 text-sm mb-1"
                      htmlFor="passwordSignIn"
                    >
                      {t('label_password')}
                      {' '}
                      <span className="text-red-600">*</span>
                    </label>
                    <input
                      className="appearance-none border rounded w-full py-1.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="passwordSignIn"
                      type="password"
                      placeholder={t('P.password')}
                      onChange={(event) => setPasswordLogin(event.target.value)}
                      required
                    />
                  </ith-div>
                  <ith-div class=" ml-4 mb-4 mt-2 flex md:justify-center justify-end">
                    <button
                      // onClick={() => click(2)}
                      type="submit"
                      className="gridColor inline-flex justify-center w-32 items-center px-2 py-1 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-green-100 focus:outline-none"
                    >
                      {t('label_login')}
                    </button>
                  </ith-div>
                  <ith-div class="flex mb-4 justify-center lg:col-start-3  md:col-start-3">
                    <ith-p class="text-sm text-blue-400 cursor-pointer" onClick={() => passwordForgoten()}>{t('auth.forgotPassword')}</ith-p>
                  </ith-div>
                </ith-div>
              </form>
              <ith-div class="grid grid-cols-1 gap-4 items-center justify-between md:p-5 md:py-1 p-3 ">
                <ith-p class="text-lg ">
                  {t('label_Or')}
                  {' '}
                  {t('label_signup')}
                </ith-p>
              </ith-div>
              <form action="#" onSubmit={() => { click(3); return false; }}>
                <ith-div class="grid lg:grid-cols-3  grid-cols-1  flex items-end ml-8 md:mr-1 mr-8">
                  <ith-div class=" ml-4 mb-4 mt-2">
                    <label
                      className="block text-gray-700 text-sm mb-1"
                      htmlFor="surnameSignUp"
                    >
                      {t('label_firstName')}
                      {' '}
                      <span className="text-red-600">*</span>
                    </label>
                    <input
                      className="appearance-none border rounded w-full py-1.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="surnameSignUp"
                      type="text"
                      value={surnameSignUp}
                      placeholder={t('P.firstName')}
                      onChange={(event) => setSurnameSignUp(event.target.value)}
                      required
                    />
                  </ith-div>
                  <ith-div class=" ml-4 mb-4 mt-2">
                    <label
                      className="block text-gray-700 text-sm mb-1"
                      htmlFor="nameSignUp"
                    >
                      {t('label_lastName')}
                      {' '}
                      <span className="text-red-600">*</span>
                    </label>
                    <input
                      className="appearance-none border rounded w-full py-1.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="nameSignUp"
                      type="text"
                      value={nameSignUp}
                      placeholder={t('P.lastName')}
                      onChange={(event) => setNameSignUp(event.target.value)}
                      required
                    />
                  </ith-div>
                </ith-div>
                {isITW ? (
                  <>
                    <ith-div class="grid lg:grid-cols-3  grid-cols-1  flex items-end ml-8 md:mr-1 mr-8">
                      <ith-div class=" ml-4 mb-4 mt-2">
                        <label
                          className="block text-gray-700 text-sm mb-1"
                          htmlFor="title"
                        >
                          {t('label_title')}
                          {' '}
                          <span className="text-red-600">*</span>
                        </label>
                        <select
                          className="border rounded w-full py-1.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="title"
                          type="text"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          required
                        >
                          <option value="" selected disabled hidden> </option>
                          <option value="T_MR">{t('T_MR')}</option>
                          <option value="T_MS">{t('T_MS')}</option>
                          <option value="T_MRS">{t('T_MRS')}</option>
                          <option value="T_FAM">{t('T_FAM')}</option>
                          <option value="T_OTHER">{t('T_OTHER')}</option>
                        </select>
                      </ith-div>
                      <ith-div class=" ml-4 mb-4 mt-2">
                        <label
                          className="block text-gray-700 text-sm mb-1"
                          htmlFor="gender"
                        >
                          {t('label_gender')}
                          {' '}
                          <span className="text-red-600">*</span>
                        </label>
                        <select
                          className="border rounded w-full py-1.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="gender"
                          type="text"
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                          required
                        >
                          <option value="" selected disabled hidden> </option>
                          <option value="Male">{t('Male')}</option>
                          <option value="Female">{t('Female')}</option>
                          <option value="Other">{t('Other')}</option>
                        </select>
                      </ith-div>
                    </ith-div>
                    <ith-div class="grid lg:grid-cols-3  grid-cols-1  flex items-end ml-8 md:mr-1 mr-8">
                      <ith-div class=" ml-4 mb-4 mt-2">
                        <label
                          className="block text-gray-700 text-sm mb-1"
                          htmlFor="zipCode"
                        >
                          {t('label_zip')}
                          {' '}
                          <span className="text-red-600">*</span>
                        </label>
                        <input
                          className="appearance-none border rounded w-full py-1.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="zipCode"
                          type="text"
                          value={zipCode}
                          placeholder={t('P.zip')}
                          onChange={(e) => setZipCode(e.target.value)}
                          required
                        />
                      </ith-div>
                      <ith-div class=" ml-4 mb-4 mt-2">
                        <label
                          className="block text-gray-700 text-sm mb-1"
                          htmlFor="city"
                        >
                          {t('label_place')}
                          {' '}
                          <span className="text-red-600">*</span>
                        </label>
                        <input
                          className="appearance-none border rounded w-full py-1.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="city"
                          type="text"
                          value={city}
                          placeholder={t('P.place')}
                          onChange={(e) => setCity(e.target.value)}
                          required
                        />
                      </ith-div>
                    </ith-div>
                    <ith-div class="grid lg:grid-cols-3  grid-cols-1  flex items-end ml-8 md:mr-1 mr-8">
                      <ith-div class=" ml-4 mb-4 mt-2">
                        <label
                          className="block text-gray-700 text-sm mb-1"
                          htmlFor="street"
                        >
                          {t('label_street')}
                          {' '}
                          <span className="text-red-600">*</span>
                        </label>
                        <input
                          className="appearance-none border rounded w-full py-1.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="street"
                          type="text"
                          value={street}
                          placeholder="P.street"
                          onChange={(e) => setStreet(e.target.value)}
                          required
                        />
                      </ith-div>
                      <ith-div class=" ml-4 mb-4 mt-2">
                        <label
                          className="block text-gray-700 text-sm mb-1"
                          htmlFor="number"
                        >
                          {t('label_building')}
                          {' '}
                          <span className="text-red-600">*</span>
                        </label>
                        <input
                          className="appearance-none border rounded w-full py-1.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="number"
                          type="text"
                          value={number}
                          placeholder="P.building"
                          onChange={(e) => setNumber(e.target.value)}
                          required
                        />
                      </ith-div>
                    </ith-div>
                    <ith-div class="grid lg:grid-cols-3  grid-cols-1  flex items-end ml-8 md:mr-1 mr-8">
                      <ith-div class=" ml-4 mb-4 mt-2">
                        <label
                          className="block text-gray-700 text-sm mb-1"
                          htmlFor="birthday"
                        >
                          Birthday
                          {' '}
                          <span className="text-red-600">*</span>
                        </label>
                        <input
                        // className="appearance-none border rounded w-full py-1.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          className="appearance-none border rounded w-full py-1.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-10"
                          id="birthday"
                          type="date"
                          value={birthday}
                          max={moment().format('YYYY-MM-DD')}
                          placeholder=""
                          onChange={(e) => setBirthday(e.target.value)}
                          required
                        />
                      </ith-div>
                    </ith-div>
                  </>
                ) : ''}
                <ith-div class="grid lg:grid-cols-3 grid-cols-1  flex items-end ml-8 md:mr-1 mr-8">
                  <ith-div class=" ml-4 mb-4 mt-2">
                    <label
                      className="block text-gray-700 text-sm mb-1"
                      htmlFor="emailSignUp"
                    >
                      {t('label_email')}
                      {' '}
                      <span className="text-red-600">*</span>
                    </label>
                    <input
                      className="appearance-none border rounded w-full py-1.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="emailSignUp"
                      type="email"
                      value={emailSignUp}
                      placeholder={t('placeholder_email')}
                      onChange={(event) => setEmailSignUp(event.target.value)}
                      required
                    />
                  </ith-div>
                  <ith-div class=" ml-4 mb-4 mt-2">
                    <label
                      className="block text-gray-700 text-sm mb-1"
                      htmlFor="signup-form_phone"
                    >
                      {t('label_phone')}
                      {' '}
                      <span className="text-red-600">*</span>
                    </label>
                    <IntlTelInput
                      fieldId="signup-form_phone"
                      containerClassName="intl-tel-input w-full"
                      nationalMode={false}
                      separateDialCode
                      preferredCountries={['ch', 'de', 'li', 'it', 'fr']}
                      inputClassName="appearance-none border rounded w-full py-1.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      autoHideDialCode={false}
                      onPhoneNumberChange={(isValid, phoneInputValue, country, fullPhoneInput) => onHandlePhoneSignUp(isValid, phoneInputValue, country, fullPhoneInput)}
                      // onPhoneNumberChange={debouncedOnPhoneNumberChange}
                      // onPhoneNumberBlur={() => setIsPhoneInputTouched(true)}
                      format
                      formatOnInit={false}
                      required
                      value={phoneValue}
                    />
                  </ith-div>
                  <ith-div class=" ml-4 mb-4 mt-2 flex md:justify-center justify-end">
                    <button
                      type="submit"
                      className="gridColor inline-flex justify-center w-32 items-center px-2 py-1 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-green-100 focus:outline-none"
                    >
                      {t('auth.signUp')}
                    </button>
                  </ith-div>
                </ith-div>
              </form>
            </>
          ) : (
            <></>
          )}
        </ith-div>
      ) : (
        <></>
      )}
    </>
  );
}
export default Data;
