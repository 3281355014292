/* eslint-disable prefer-destructuring */

/* eslint-disable import/no-named-as-default-member */

/* eslint-disable eqeqeq */

/* eslint-disable no-use-before-define */

/* eslint-disable no-shadow */
import Popover from '@material-ui/core/Popover';
import React, { useEffect, useState } from 'react';

import BookingScreen from '../../../components/minalScreen/Booking/Booking';
import Password from '../../../components/minalScreen/Password/Password';
import PasswordForgoten from '../../../components/minalScreen/PasswordForgoten/PasswordForgoten';
import ThankYou from '../../../components/minalScreen/ThankYou/ThankYou';
import Verification from '../../../components/minalScreen/Verification/Verification';
import Data from '../../../components/minalScreen/YourData/YourData';
import config from '../../../config/env';
import appService, { fetchMe } from '../../../embed/src/api/api/appService';
import appointmentService from '../../../embed/src/api/api/appointmentService';
import jwtService from '../../../embed/src/api/api/jwtService';
import APPOINT_STATUSES from '../../../embed/src/constants/appointmentStatuses';
import EmbedError from '../../../embed/src/containers/EmbedAuthModals/EmbedError';
import './style.css';

function Minmal({
  centre,
  providers,
  serviceVariants,
  t,
  preselectedProviderId,
  preselectedProviderShowSelected,
  preselectedProviderSelectButHide,
  preselectedServiceId,
  preselectedServiceShowSelected,
  preselectedServiceSelectButHide,
}) {
  const [bookingState, setBookingState] = useState(true);
  const [bookingStateChecked, setBookingStateChecked] = useState(false);
  const [dataStateChecked, setDataStateChecked] = useState(false);
  const [passwordStateChecked, setPasswordStateChecked] = useState(false);
  const [dataState, setDataState] = useState(false);
  const [passwordState, setPasswordState] = useState(false);
  const [bookingStateShow, setbookingStateShow] = useState(true);
  const [dataStateShow, setdataStateShow] = useState(false);
  const [passwordStateShow, setpasswordStateShow] = useState(false);
  const [passwordForgotenStateShow, setPasswordForgotenStateShow] = useState(false);
  const [verificationStateShow, setverificationStateShow] = useState(false);
  const [thankYouStateShow, setThankYouStateShow] = useState(false);
  const [providerState, setproviderState] = useState('');
  const [serviceState, setserviceState] = useState('');
  const [dateState, setdateState] = useState('');
  const [emailState, setemailState] = useState('');
  const [surnameSignupState, setsurnameSignupState] = useState('');
  const [nameSignupState, setnameSignupState] = useState('');
  const [emailSignupState, setemailSignupState] = useState('');
  const [phoneSignupState, setphoneSignupState] = useState('');
  const [passwordLoginState, setpasswordLoginState] = useState('');
  const [passwordSignupState, setpasswordSignupState] = useState('');
  const [emailPasswordForgotenState, setEmailPasswordForgotenState] = useState('');
  const [user, setUser] = useState('');
  const [signIn, setSignIn] = useState(false);
  const [signUp, setSignUp] = useState(false);
  const [confirmationSuccess, setConfirmationSuccess] = useState(false);
  const [sendCode, setSendCode] = useState(false);
  const [checkValidCode, setCheckValidCode] = useState(false);
  const [code, setCode] = useState('');
  const [gender, setGender] = useState('');
  const [title, setTitle] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [birthday, setBirthday] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showConfirmationButton, setShowConfirmationButton] = useState(false);

  // ToDo: use maybe a Reducer to switch the states of visibality

  const getUserFromLocalStorage = async () => {
    let user;
    try {
      user = await fetchMe();
    } catch (error) {
      console.log(error);
    }
    if (user) {
      // moment.locale(user.settings.general.language)
      setUser(user);
    }
  };

  const isITW = config.config.brandName === 'Therapeutenbuchen';

  useEffect(() => {
    if (!user) {
      getUserFromLocalStorage();
    }
    // setShit(false) -> to set everything false first?
    if (user && confirmationSuccess) {
      setPasswordForgotenStateShow(false);
      setShowConfirmationButton(false);
      setbookingStateShow(false);
      setdataStateShow(false);
      setpasswordStateShow(false);
      setverificationStateShow(false);
      setThankYouStateShow(true);
    }
    if (user && signUp && !confirmationSuccess) {
      setPasswordForgotenStateShow(false);
      setPasswordState(false);
      setverificationStateShow(true);
      setBookingStateChecked(true);
      setDataStateChecked(true);
      setPasswordStateChecked(true);
    }
    if (user && signUp && sendCode) {
      setPasswordForgotenStateShow(false);
      onVerificationSubmit();
      setSendCode(false);
    }
    if (user && code && checkValidCode) {
      setPasswordForgotenStateShow(false);
      onSendCodeSubmit(user.id);
      setCheckValidCode(false);
      onBookClick();
    }
    if (user && signIn && !confirmationSuccess) {
      setPasswordForgotenStateShow(false);
      onBookClick();
    }
  });

  const bookingHandler = (e) => {
    if (e === 1) {
      setBookingState(true);
      setShowConfirmationButton(false);
      setDataState(false);
      setPasswordState(false);
    } else if (e === 2) {
      if (user) {
        setBookingState(false);
        setShowConfirmationButton(true);
      } else if (providerState != '' && serviceState != '' && dateState != '') {
        setBookingState(false);
        setDataState(true);
        setdataStateShow(true);
      }
    }
  };

  const dataHandler = (e) => {
    setPasswordForgotenStateShow(false);
    if (e === 1) {
      setDataState(true);
      setPasswordState(false);
      setBookingState(false);
    } else if (e === 2) {
      onSignInSubmit();
    } else if (e === 3) {
      setDataState(false);
      setPasswordState(true);
      setpasswordStateShow(true);
    }
  };

  const PasswordHandler = (e) => {
    if (e === 1) {
      setPasswordState(true);
      setBookingState(false);
      setDataState(false);
    } else if (e === 2) {
      onSignUpSubmit();
    }
  };

  const passwordForgotenHandler = (e) => {
    if (e === 1) {
      setPasswordForgotenStateShow(false);
    } else if (e === 2) {
      if (emailPasswordForgotenState) {
        sendPasswortForgotenMail();
        setPasswordForgotenStateShow(false);
      } else {
        console.log('empty');
      }
    }
  };

  const ThankYouHandler = (e) => {
    if (e === 1) {
      setbookingStateShow(true);
      setdataStateShow(true);
      setpasswordStateShow(true);
      setThankYouStateShow(false);
    }
  };

  const sendPasswortForgotenMail = async () => {
    try {
      await appService.sendResetPasswordLink(emailPasswordForgotenState);
    } catch (error) {
      // ToDo: Messega for User
      console.log(error);
    }
  };

  const VerificationHandler = (e) => {
    if (e === 1) {
      setCheckValidCode(true);
    }
  };

  const onSignInSubmit = async () => {
    try {
      const signInData = {
        email: emailState,
        password: passwordLoginState,
      };

      if (!emailState || !passwordLoginState) {
        return;
      }

      const { signInToken, refreshToken } = await appService.signIn(signInData);

      jwtService.setAccessToken(signInToken);
      jwtService.setRefreshToken(refreshToken);

      const user = await fetchMe();

      setUser(user);
      setSignIn(true);
    } catch (e) {
      errorHandler(e);
      console.log(e);
    }
  };

  const onSignUpSubmit = async () => {
    try {
      let langKey = localStorage.getItem('i18nextLng') || 'de';
      if (langKey.indexOf('-') !== -1) {
        langKey = langKey.split('-')[0];
      }

      let dataToSignUp;
      if (isITW) {
        dataToSignUp = {
          firstName: surnameSignupState,
          lastName: nameSignupState,
          email: emailSignupState,
          phone: phoneSignupState,
          sex: gender,
          title,
          zipcode: zipCode,
          city,
          street,
          building: number,
          dateOfBirth: birthday,
          password: passwordSignupState,
          langKey,
        };
      } else {
        dataToSignUp = {
          firstName: surnameSignupState,
          lastName: nameSignupState,
          email: emailSignupState,
          phone: phoneSignupState,
          countryPhone: 'CH',
          password: passwordSignupState,
          langKey,
        };
      }

      const signUpRequest = appService.signUp;

      const { user } = await signUpRequest(dataToSignUp);
      setUser(user);
      setSignUp(true);
      setSendCode(true);
    } catch (e) {
      errorHandler(e);
      console.log(e);
    }
  };

  const onBookClick = async () => {
    try {
      const data = {
        providerId: providerState.centreProvider_id,
        serviceId: serviceState.service_id,
        booking_fk: serviceState.service_id,
        date: dateState.date,
        startTime: dateState.startTime,
        endTime: dateState.endTime,
        status: APPOINT_STATUSES.CONFIRMED,
        centreId: centre.centre_id,
        description: '',
        consumerId: user.user_id,
        createdByUserId: user.user_id,
        dirPartnerId: centre.centre_id,
      };

      await appointmentService.createAppointment(data);

      setConfirmationSuccess(true);
    } catch (e) {
      errorHandler(e);
      console.log(e);
    }
  };

  const onVerificationSubmit = async () => {
    try {
      await appService.sendActivationCode(user.id);
    } catch (e) {
      errorHandler(e);
      console.log(e);
    }
  };

  const onSendCodeSubmit = async (userId) => {
    try {
      let langKey = localStorage.getItem('i18nextLng') || 'de';
      if (langKey.indexOf('-') !== -1) {
        langKey = langKey.split('-')[0];
      }

      const { signInToken, refreshToken } = await appService.activateWithCode({
        code,
        userId,
        langKey,
      });

      jwtService.setAccessToken(signInToken);
      jwtService.setRefreshToken(refreshToken);

      const user = await fetchMe();

      setUser(user);
    } catch (e) {
      errorHandler(e);
      console.log(e);
    }
  };

  const errorHandler = (e) => {
    try {
      const {
        status,
        data: { error },
      } = e.response;

      if (status >= 400 && status < 500) {
        if (error.userId && error.isSmsAttemptsExhausted) {
          // this.setState({
          //   userId: error.userId,
          // });
          console.log('do something');
          return;
        }

        if (error.userId) {
          // this.setState({
          //   userId: error.userId,
          // });
          console.log('do something');
          return;
        }
      }

      setErrorMessage(
        typeof error.message === 'string' ? error.message : 'An error occurred',
      );
      setShowErrorModal(true);
    } catch (e) {
      console.log('error makes error', e);
    }
  };

  const getPrice = (service, centre) => {
    const price = service.estimatedPrice;
    return price != 0 ? `${centre.currency || 'CHF'} ${price}` : '';
  };

  // toDo: Use Reducer to make visible and not visible and stuff
  return (
    <ith-div
      id="anchorEl"
      class="grid md:grid-cols-1 grid-cols-1 gap-5 items-start xl:w-4/5 w-full p-4 mx-auto bg-white "
    >
      <BookingScreen
        t={t}
        click={(e) => bookingHandler(e)}
        show={bookingState}
        setprovider={(f) => setproviderState(f)}
        provider={providerState}
        setservice={(f) => setserviceState(f)}
        service={serviceState}
        visible={bookingStateShow}
        checked={bookingStateChecked}
        setDate={(slot) => setdateState(slot)}
        dateSlot={dateState}
        providers={providers}
        centre={centre}
        serviceVariants={serviceVariants}
        getPrice={(s, c) => getPrice(s, c)}
        preselectedProviderId={preselectedProviderId}
        preselectedProviderShowSelected={preselectedProviderShowSelected}
        preselectedProviderSelectButHide={preselectedProviderSelectButHide}
        preselectedServiceId={preselectedServiceId}
        preselectedServiceShowSelected={preselectedServiceShowSelected}
        preselectedServiceSelectButHide={preselectedServiceSelectButHide}
      />
      {dataStateShow && (
        <Data
          t={t}
          click={(e) => dataHandler(e)}
          show={dataState}
          visible={dataStateShow}
          checked={dataStateChecked}
          setEmail={(f) => setemailState(f)}
          setPasswordLogin={(f) => setpasswordLoginState(f)}
          setSurnameSignUp={(f) => setsurnameSignupState(f)}
          setNameSignUp={(f) => setnameSignupState(f)}
          setTitle={(f) => setTitle(f)}
          setGender={(f) => setGender(f)}
          setZipCode={(f) => setZipCode(f)}
          setCity={(f) => setCity(f)}
          setStreet={(f) => setStreet(f)}
          setNumber={(f) => setNumber(f)}
          setBirthday={(f) => setBirthday(f)}
          setEmailSignUp={(f) => setemailSignupState(f)}
          setPhoneSignUp={(f) => setphoneSignupState(f)}
          surnameSignUp={surnameSignupState}
          nameSignUp={nameSignupState}
          title={title}
          gender={gender}
          zipCode={zipCode}
          city={city}
          street={street}
          number={number}
          birthday={birthday}
          emailSignUp={emailSignupState}
          phoneSignUp={phoneSignupState}
          isITW={isITW}
          passwordForgoten={() => setPasswordForgotenStateShow(true)}
        />
      )}
      {passwordForgotenStateShow && (
        <PasswordForgoten
          t={t}
          click={(e) => passwordForgotenHandler(e)}
          visible={passwordForgotenStateShow}
          setEmail={(e) => setEmailPasswordForgotenState(e)}
        />
      )}
      {passwordStateShow && (
        <Password
          t={t}
          click={(e) => PasswordHandler(e)}
          show={passwordState}
          visible={passwordStateShow}
          checked={passwordStateChecked}
          setPasswordSignUp={(f) => setpasswordSignupState(f)}
          passwordSignUp={passwordSignupState}
        />
      )}
      {verificationStateShow && (
        <Verification
          t={t}
          visible={verificationStateShow}
          click={(e) => VerificationHandler(e)}
          setCode={(e) => setCode(e)}
          setSendCode={() => setSendCode(true)}
        />
      )}
      {thankYouStateShow && (
        <ThankYou
          t={t}
          show={thankYouStateShow}
          click={(e) => ThankYouHandler(e)}
          providers={providers}
          centre={centre}
          provider={providerState}
          service={serviceState}
          dateSlot={dateState}
          getPrice={(s, c) => getPrice(s, c)}
        />
      )}
      {showConfirmationButton ? (
        <ith-div class="mt-3 flex justify-center">
          <ith-button
            onClick={() => onBookClick()}
            class="gridColor inline-flex justify-center w-32 items-center px-2 py-1 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-green-100 focus:outline-none"
          >
            {t('label_confirm')}
          </ith-button>
        </ith-div>
      ) : (
        ''
      )}
      <Popover
        id="errorModal"
        onClose={() => setShowErrorModal(false)}
        open={showErrorModal}
        anchorEl={document.getElementById('anchorEl')}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          horizontal: 'center',
        }}
      >
        <EmbedError
          message={errorMessage}
          onClose={() => setShowErrorModal(false)}
        />
      </Popover>
    </ith-div>
  );
}

export default Minmal;
